import { combineReducers } from 'redux';
import user from './user';
import header from './header';

export const reducer = combineReducers({
    user,
    header
});

export type RootState = ReturnType<typeof reducer>;

export default reducer;