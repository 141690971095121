import React from 'react';
import { Button, Select, Input, DatePicker } from 'components';
import { UserService } from 'services';
import moment from 'moment';

interface Props {
	onClose: any;
	global_conversion?: any;
}

class ModalChangeConversion extends React.Component<Props> {

	state = {
		form: {
			amount: 1
		}
	}

	componentDidMount() {
		if (this.props.global_conversion) {
			this.setState({
				form: {
					...this.state.form,
					amount: this.props.global_conversion?.amount || 1
				}
			});
		}
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	submit = async () => {
		const res: any = await UserService.updateConversion({
			...this.state.form,
		});
		this.props.onClose(res.global_conversion);
	}

	render() {
		return (
			<div id="modal-create-user">
				<div className="content-create row">
					<div className="col-md-12">
						<Input 
							type="decimal"
							value={ this.state.form.amount }
							name="amount" 
							label="Monto"
							onChange={ this.change } />
					</div>
				</div>
				<div className="row row-buttons">
					<div className="col-6 text-center">
						<Button type="button" className="btn-close" onClick={ () => this.props.onClose() }>
							Cerrar
						</Button>
					</div>
					<div className="col-6 text-center">
						<Button type="button" className="btn-save btn-green" onClick={ () => this.submit() }>
							Guardar
						</Button>
					</div>
				</div>
			</div>
		)
	}
}

export default ModalChangeConversion;