import { Api } from 'utils';

class PaymentReportService {
	
	static get = async (params?: any) => Api.createResponse('superadmin/payment-report/get',params);
	static getData = async (params?: any) => Api.createResponse('superadmin/payment-report/get-data',params);
	static create = async (params?: any) => Api.createResponse('superadmin/payment-report/create',params);
	static download = async (params?: any) => Api.createResponse('superadmin/payment-report/download',params);
	static changeStatus = async (params?: any) => Api.createResponse('superadmin/payment-report/change-status',params);
	
}

export default PaymentReportService;