import React from 'react';
import { Button, Select, Input, DatePicker, SelectSearch } from 'components';
import { PaymentReportService } from 'services';
import moment from 'moment';
import { Socket, SocketEvents, Globals } from 'utils';

interface Props {
	onClose: () => void;
	users: any;
	plans: any;
	payment_methods: any;
}

class ModalCreatePayment extends React.Component<Props> {

	state = {
		form: {
			plan_id: '',
			payment_method_id: '',
			amount: '',
			next_payment: '',
			user_id: ''
		},
		user: null
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	submit = async () => {
		if (!this.state.form.user_id) {
			Globals.showError("Debe ingresar el usuario del pago");
			return;
		}

		if (!this.state.form.payment_method_id) {
			Globals.showError("Debe ingresar el método de pago");
			return;
		}

		if (!this.state.form.amount) {
			Globals.showError("Debe ingresar el monto pagado");
			return;
		}

		if (!this.state.form.plan_id) {
			Globals.showError("Debe ingresar el plan");
			return;
		}

		if (!this.state.form.next_payment) {
			Globals.showError("Debe ingresar la fecha de corte");
			return;
		}

		await PaymentReportService.create({
			...this.state.form,
			next_payment: this.state.form.next_payment ? moment(this.state.form.next_payment).format('YYYY-MM-DD') : ''
		});
		Socket.emit(SocketEvents.UPDATE_SUPERADMIN,{
			admin_id: this.state.form.user_id
		});

		this.props.onClose();
	}

	render() {
		const user: any = this.props.users?.find((i: any) => i.id == this.state.form.user_id);

		return (
			<div id="modal-create-payment">
				<div className="row">
					<div className="col-md-12">
						<SelectSearch
							label="Seleccionar Usuario"
							onChange={ (user: any) => {
								this.setState({
									form: {
										...this.state.form,
										user_id: user?.value
									}
								},() => {
									if (user.plan_user?.plan) {
										this.setState({
											form: {
												...this.state.form,
												plan_id: user.plan_user?.plan_id || '',
												amount: user.plan_user?.plan?.price || ''
											}
										});
									}
								});
							} }
							color="white"
							searchRight
							value={ user ? {
								value: user.id,
								label: user.name
							} : null }
							options={ this.props.users.map((i: any) => {
								return {
									value: i.id,
									label: i.name + ' - ' + i.email,
									plan_user: i.plan_user
								}
							}) } />
					</div>
					{
						this.state.form.user_id != '' && (
							<React.Fragment>
								<div className="col-md-6">
									<Select
										name="plan_id"
										label="Plan"
										onChange={ (e: any) => this.change(e) }
										value={ this.state.form.plan_id }
										options={ this.props.plans.map((item: any) => {
											return {
												value: item.id,
												label: item.name + (item.price == 0 ? '' : ' (' + (item.quantity_users ? (item.quantity_users + ' usuario(s)') : 'Ilimitado') + ')')
											}
										}) } />
								</div>
								<div className="col-md-6">
									<DatePicker
										showYearDropdown={ true }
										minDate={ moment().add(1,'day').toDate() }
										label="Fecha de corte"
										onChange={ (text: string) => {
											this.change({
												target: {
													value: text,
													name: 'next_payment'
												}
											});
										} }
										value={ this.state.form.next_payment }
									/>
								</div>
								<div className="col-md-6">
									<Input 
										type="decimal"
										value={ this.state.form.amount }
										name="amount" 
										label="Monto $"
										onChange={ this.change } />
								</div>
								<div className="col-md-6">
									<Select
										name="payment_method_id"
										label="Método de Pago"
										onChange={ (e: any) => this.change(e) }
										value={ this.state.form.payment_method_id }
										options={ this.props.payment_methods.map((item: any) => {
											return {
												value: item.id,
												label: item.name
											}
										}) } />
								</div>
							</React.Fragment>
						)
					}
				</div>
				{
					this.state.form.user_id != '' && (
						<div className="row row-buttons">
							<div className="col-6 text-center">
								<Button type="button" className="btn-close" onClick={ () => this.props.onClose() }>
									Cerrar
								</Button>
							</div>
							<div className="col-6 text-center">
								<Button type="button" className="btn-save btn-green" onClick={ () => this.submit() }>
									Guardar
								</Button>
							</div>
						</div>
					)
				}
			</div>
		)
	}
}

export default ModalCreatePayment;