import React from 'react';
import { Pagination, Table, Input, Button, Select, Tooltip, Icon, Modal, DatePicker } from 'components';
import { connect } from 'react-redux';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Constants, Globals, Print, Socket, SocketEvents } from 'utils';
import moment from 'moment';
import Header from 'screens/header';
import { PaymentReportService } from 'services';
import ModalCreatePayment from './modal-create-payment';
import { AcceptedIcon, RejectedIcon } from 'assets/icons';

interface LocationProps {
	page: number | undefined;
	form: any
}

interface Props extends RouteComponentProps<{},{},LocationProps> {
	dispatch: any
}

const INITIAL_STATE = {
	name: '',
	since: '',
	until: '',
	status: ''
}

class PaymentReport extends React.Component<Props> {

	timer: any = undefined;

	state = {
		data: [],
		page: 1,
		last_page: 1,
		form: INITIAL_STATE,
		header: [
			'Fecha del Pago',
			'ID (Cliente)',
			'Nombre',
			'Plan',
			'Fecha de Vencimiento',
			'Monto Pagado',
			'Medio de Pago',
			'Referencia',
			'Estatus',
			'Acciones'
		],
		visible: false,
		payment_methods: [],
		users: [],
		plans: []
	}

	async componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Reporte de Pagos',
				onBack: () => {
					this.props.history.replace('/');
				}
			}
		});

		this.loadData();
		this.load();
	}

	loadData = async () => {
		const res: any = await PaymentReportService.getData({
			withoutLoading: true
		});
		this.setState({
			users: res.users,
			plans: res.plans,
			payment_methods: res.payment_methods
		});
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await PaymentReportService.get({
			page: this.state.page,
			withoutLoading,
			...this.state.form,
			since: this.state.form.since && moment(this.state.form.since).format('YYYY-MM-DD'),
			until: this.state.form.until && moment(this.state.form.until).format('YYYY-MM-DD'),
		});
		this.setState({
			data: res.payments.data,
			last_page: res.payments.last_page
		});
	}

	exportXLS = async () => {
		const res: any = await PaymentReportService.download({
			...this.state.form,
		});
	    Print(Constants.BASE_STORAGE + res.url);
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}
		
	onClose = () => {
		this.setState({
			visible: false
		},() => this.load(true));
	}

	accept = (e: any, item: any) => {
		e.preventDefault();
		Globals.confirm('¿Aceptar el pago?',async () => {
			await PaymentReportService.changeStatus({
				status: Constants.PLAN_PAYMENT_STATUS.PAID,
				payment_id: item.id
			});
			Socket.emit(SocketEvents.UPDATE_SUPERADMIN,{
				admin_id: item?.id
			});
			this.load(true);
		});
	}

	reject = (e: any, item: any) => {
		e.preventDefault();
		Globals.confirm('¿Rechazar el pago?',async () => {
			await PaymentReportService.changeStatus({
				status: Constants.PLAN_PAYMENT_STATUS.REJECTED,
				payment_id: item.id
			});
			Socket.emit(SocketEvents.UPDATE_SUPERADMIN,{
				admin_id: item?.id
			});
			this.load(true);
		});
	}

	render() {
		const { visible } = this.state;

		return (
			<div id="admin-payment-report">
				<Modal
		          className="modal-create-user"
		          visible={ visible }
		          title="Crear pago"
		          onClose={ () => this.onClose() }
		        >
		          <ModalCreatePayment
		          	users={ this.state.users }
		          	plans={ this.state.plans }
		          	payment_methods={ this.state.payment_methods }
		            onClose={ () => this.onClose() } />
		      	</Modal>

				<Header>
					<li onClick={ () => this.props.history.replace('/') }>
						Inicio
					</li>
					<li onClick={ () => {
						this.setState({
							visible: true
						});
					} }>
						Registrar Pago
					</li>
		      	</Header>

				<div className="row row-total">
					<div className="col-md-9 row">
						<div className="col-md-3">
							<Input
								searchRight
								value={ this.state.form.name }
								name="name"
								showYearDropdown={ true }
								label="Búsqueda por nombre"
								onChange={ (e: any) => {
									clearTimeout(this.timer);
									this.timer = setTimeout(() => {
										this.load(true);
									},1000);
									this.change(e); 
								} } />
						</div>
						<div className="col-md-3">
							<Select
								color="white"
								name="status"
								label="Estatus"
								onChange={ (e: any) => this.change(e,() => {
									this.load(true);
								}) }
								placeholder="Todos"
								value={ this.state.form.status }
								options={ [
									{ value: Constants.PLAN_PAYMENT_STATUS.PAID.toString(), label: 'Pagados' },
									{ value: Constants.PLAN_PAYMENT_STATUS.TO_CONFIRM.toString(), label: 'Por confirmar' },
									{ value: Constants.PLAN_PAYMENT_STATUS.REJECTED.toString(), label: 'Rechazados' },
								] } />
						</div>
						<div className="col-md-3">
							<DatePicker
								label="Desde"
								showYearDropdown={ true }
								maxDate={ this.state.form.until ? moment(this.state.form.until).toDate() : null }
								onChange={ (text: string) => {
									this.change({
										target: {
											value: text,
											name: 'since'
										}
									},async () => {
										await this.setState({
											page: 1
										});
										this.load(true);
									});
								} }
								value={ this.state.form.since }
							/>
						</div>
						<div className="col-md-3">
							<DatePicker
								label="Hasta"
								minDate={ this.state.form.since ? moment(this.state.form.since).toDate() : moment().toDate() }
								onChange={ (text: string) => {
									this.change({
										target: {
											value: text,
											name: 'until'
										}
									},async () => {
										await this.setState({
											page: 1
										});
										this.load(true);
									});
								} }
								value={ this.state.form.until }
							/>
						</div>
					</div>
					<div className="col-md-3 text-right">
						<Button onClick={ () => this.exportXLS() }>
							Exportar XLS
						</Button>
					</div>
				</div>

				{
					this.state.form != INITIAL_STATE && (
						<p className="btn-reset" onClick={ this.reset }>Limpiar filtros</p>
					)
				}

				<Table data={ this.state.data.length } title="" header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ moment(i.created_at).format('DD/MM/YYYY') }</td>
								<td>{ i.plan_user?.user?.id }</td>
								<td>{ i.plan_user?.user?.name }</td>
								<td>{ i.plan_user?.plan?.name }</td>
								<td>{ i.next_payment != null ? moment(i.next_payment).format('DD/MM/YYYY') : '-' }</td>
								<td>{ Globals.formatMiles(i.amount) }</td>
								<td>{ i?.plan_payment_method?.name || '' }</td>
								<td>{ i.reference_number }</td>
								<td>{ i.status_text }</td>
								<td>
									{
										i.status == Constants.PLAN_PAYMENT_STATUS.TO_CONFIRM && (
											<React.Fragment>
												<Tooltip title="Aceptar">
													<a className="link-icon" href="#" onClick={ (e: any) => this.accept(e,i) }>
														<img src={ AcceptedIcon } />
													</a>
												</Tooltip>
												<Tooltip title="Rechazar">
													<a className="link-icon link-danger" href="#" onClick={ (e: any) => this.reject(e,i) }>
														<img src={ RejectedIcon } />
													</a>
												</Tooltip>
											</React.Fragment>
										)
									}
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect()(PaymentReport);