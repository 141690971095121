import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'reducers';
import { useHistory, Link } from 'react-router-dom';
import { Globals, Constants } from 'utils';
import { Logo } from 'assets/img';

interface Props {
	className?: string,
	children?: React.ReactNode
}

const logout = (dispatch: any,history: any) => {
	Globals.confirm('¿Desea cerrar sesión?',() => {
		dispatch({
			type: 'SET_USER',
			payload: null
		});
		history.replace('/login');
		Globals.quitLoading();
	});
}

const Header = (props: Props) => {
	const header = useSelector((state: RootState) => state.header);
	const [open,setOpen] = useState(false);
	const history = useHistory();
	const dispatch = useDispatch();

	return (
		<div id="header">
			<div className="row">
				<div className="col-md-4">
					<div className="container-logo">
						<Link to="/">
							<img src={ Logo } className="logo" />
						</Link>
						<h3>{ header?.title }</h3>
					</div>
				</div>
				<div className="col-md-8 text-right">
					<button className="focusable-container" onBlur={ () => setOpen(false) }>
						<div className="container-user" onClick={ () => setOpen(!open) }>
							<p>Menu</p>
							<i className="fa fa-caret-down"></i>
						</div>
						{
							open && (
								<ul>
									{ props.children }
									<li onClick={ () => {
										logout(dispatch,history);
										setOpen(false);
									} }>
										Cerrar Sesión
									</li>
								</ul>
							)
						}		
					</button>
				</div>
			</div>
		</div>
	)	
}

export default Header;