import React from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Constants } from '../utils';
import { RootState } from 'reducers';

const _Route = (props: any) => {
	if (!props.user && (props.auth)) {
		return <Redirect to="/login" />
	}
	else if (props.user && props.noAuth) {
		return <Redirect to="/" />
	}
	else if (props.user && (props.auth)) {
		return (
			<Route { ...props } />
		)
	}
	else {
		return <Route { ...props } />
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(_Route);