import React from 'react'
import Select from 'react-select';
import { Search } from 'assets/icons';

interface Options {
	value: string | number,
	label: string
}

interface Props {
	options: Options[],
	label?: string,
	color?: string,
	placeholder?: string,
	onChange: (e: any) => void,
	value: any,
	searchRight?: boolean
}

const customStyles = {
  option: (provided: any, state: any) => ({
  	...provided,
    fontSize: 12
  })  
}

const SelectSearch = (props: Props) => (
  <div className={ `form-group container-select-search ${ props.searchRight && 'search-right' }` }>
	{ props.label && <label>{ props.label }</label> }
	<Select 
		{ ...props }
		noOptionsMessage={ () => "No hay resultados" }
		styles={ customStyles }
		className={ `form-control ${ props.color ? props.color : '' }` }
		placeholder={ props.placeholder || '' }
		options={ props.options } />
	{
		props.searchRight && (
			<img className="image-search-input" src={ Search } />
		)
	}
  </div>
)

export default SelectSearch;