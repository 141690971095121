import React from 'react';
import { Button, Select, Input, DatePicker, Textarea, PaperClip } from 'components';
import { GlobalNotificationService } from 'services';
import { Globals, Socket, SocketEvents } from 'utils';

interface Props {
	onClose: any;
}

const STATUS = {
	ACTIVE: '1',
	INACTIVE: '0'
}

class ModalSendNotification extends React.Component<Props> {

	state = {
		form: {
			title: '',
			body: '',
			photo: '',
			status: ''
		}
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	submit = async () => {
		if (!this.state.form.title) {
			Globals.showError("Debe ingresar el título de la notificación");
			return;
		}
		const res: any = await GlobalNotificationService.send({
			hasFile: true,
			...this.state.form,
			// @ts-ignore,
			photo: this.state.form.photo?.value || ''
		});
		this.props.onClose();
		Socket.emit(SocketEvents.SEND_GLOBAL_NOTIFICATION,{
			global_notification: res.global_notification,
			ids: res.ids
		})
	}

	render() {
		return (
			<div id="modal-create-user">
				<div className="content-create row">
					<div className="col-md-12">
						<Input 
							value={ this.state.form.title }
							name="title" 
							label="Título"
							onChange={ this.change } />
					</div>
					<div className="col-md-12">
						<Textarea
							rows={ 5 }
							value={ this.state.form.body }
							name="body" 
							label="Contenido (Opcional)"
							onChange={ this.change } />
					</div>
					<div className="col-md-12">
						<Select
							color="white"
							name="status"
							label="Estatus"
							onChange={ (e: any) => this.change(e) }
							placeholder="Todos"
							value={ this.state.form.status }
							options={ [
								{ value: STATUS.ACTIVE, label: 'Activos' },
								{ value: STATUS.INACTIVE, label: 'Inactivos' },
							] } />
					</div>
					<div className="col-md-12">
						<PaperClip
							value={ this.state.form.photo }
							successText="Imagen Adjuntada"
							pendingText="Adjuntar Imagen" 
							onChange={ (e: any) => {
								this.setState({
									form: {
										...this.state.form,
										photo: e.target
									}
								})
							} } />
					</div>
				</div>
				<div className="row row-buttons">
					<div className="col-6 text-center">
						<Button type="button" className="btn-close" onClick={ () => this.props.onClose() }>
							Cerrar
						</Button>
					</div>
					<div className="col-6 text-center">
						<Button type="button" className="btn-save btn-green" onClick={ () => this.submit() }>
							Enviar
						</Button>
					</div>
				</div>
			</div>
		)
	}
}

export default ModalSendNotification;