import React from 'react';
import Title from './title';

interface Props {
	header: string[],
	data: any,
	children: React.ReactNode,
	right?: React.ReactNode,
	title: string,
	className?: string,
	center?: number[],
	rightAlign?: number[]
}

const Table = (props: Props) => {
	
	let content = (
		<table className={ `table table-component table-responsive w-100 d-block d-md-table ${ props.className }` }>
			<thead>
				<tr>
					{ props.header.map((i: string,index: number) => {
						return (
							<th className={ props.center && props.center.indexOf(index) != -1 ? 'text-center' : props.rightAlign && props.rightAlign.indexOf(index) != -1 ? 'text-right' : '' } key={ index }>{ i }</th>
						)
					}) }
				</tr>
			</thead>
			<tbody>
				{ props.children }
			</tbody>
		</table>
	)

	if (!props.data || props.data == 0) {
		content = (
			<h2 className="no-table-data">No hay registros</h2>
		)
	}

	return (
		<div className="table-container">
		
			<Title right={ props.right } name={ props.title } />
		
			{ content }
		</div>
	)
}

export default Table;