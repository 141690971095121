import React from "react";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import {
  HashRouter as Router,
  Switch,
  Redirect
} from "react-router-dom";
import "moment/locale/es";
import { Route } from "components";

import Home from "screens/home/home";
import PaymentReport from "screens/payment-report/payment-report";

// Auth
import Login from "screens/auth/login/login";

// Scripts
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import { emit } from "jetemit";

// Styles
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "./scss/main.scss";

const Container = () => {
  return (
    <Switch>
      <Route exact auth path="/" component={ Home } />
      <Route exact auth path="/payment-report" component={ PaymentReport } />

      {/* Auth */}
      <Route exact noAuth path="/login" component={ Login } />

      <Redirect to="/" />
    </Switch>
  )
}

const App = () => (
  <Provider store={ store }>
    <PersistGate loading={ null } persistor={ persistor }>
      <Router>
        <Container />
      </Router>
    </PersistGate>
  </Provider>
);

export default App;
