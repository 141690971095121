import HoldOn from 'react-hold-on';
import Swal from 'sweetalert2';
import moment from 'moment';
import $ from 'jquery';

class Globals {

	setLoading = () => {
		HoldOn.open({
		     theme: "sk-bounce"
		});
	}

	quitLoading = () => {
		HoldOn.close();
	}

	formatMiles = (n: any, decimals: boolean = true, currency: string = '$') => {
		var c: any = isNaN(c = Math.abs(c)) ? 2 : c,
			d: any = d == undefined ? "." : d,
			t: any = t == undefined ? "," : t,
			s: any = n < 0 ? "-" : "",
			i: any = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
			j: any = (j = i.length) > 3 ? j % 3 : 0;

		return currency + ' ' + s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
	}

	hideTooltip = () => {
		$('button').blur();
    }

	showSuccess = (message: string) => {
		Swal.fire({
            title: "",
            text: message,
            type: "success",
			showCancelButton: false,
			confirmButtonColor: '#3a7fc2',
        });
	}

	showError = (message?: string) => {
		Swal.fire({
            title: "",
            text: message ? message : "Se ha producido un error",
            type: "error",
			showCancelButton: false,
			confirmButtonColor: '#3a7fc2',
        });
	}

	confirm = (message: string,callback: any) => {
		this.hideTooltip();
		Swal.fire({
            title: "",
            text: message,
            type: "warning",
			showCancelButton: true,
            confirmButtonText: 'Aceptar',
			cancelButtonText: 'Cancelar',
			confirmButtonColor: '#3a7fc2',
        }).then((confirm: any) => {
        	if (confirm.value) {
        		callback();
        	}
        });
	}

	getDate = (date: string, to: string = 'DD/MM/YYYY', from: string = 'YYYY-MM-DD HH:mm:ss') => {
		return moment(date,from).format(to);
	}

	capitalize = (text: string) => {
		return text.charAt(0).toUpperCase() + text.slice(1);
	}

	pad(num: number, size: number = 4) {
	    var s = num + "";
	    while (s.length < size) s = "0" + s;
	    return s;
	}

	isPdf(file: string) {
		return file.substr(file.lastIndexOf('.') + 1) == 'pdf';
	}

	isImage(file: string) {
		const formats = ['png','jpg','jpeg'];
		return formats.indexOf(file.substr(file.lastIndexOf('.') + 1)) != -1;
	}
}

export default new Globals();