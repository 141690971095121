import React from 'react';
import { Button, Select, Input, DatePicker } from 'components';
import { UserService } from 'services';
import moment from 'moment';
import { Socket, SocketEvents } from 'utils';

interface Props {
	onClose: () => void;
	user?: any;
	plans?: any;
	admin_functionalities?: any;
}

class ModalCreateUser extends React.Component<Props> {

	state = {
		form: {
			name: '',
			email: '',
			document: '',
			phone: '',
			date: '',
			password: '',
			password_confirmation: '',
			plan_id: ''
		},
		plans: this.props.plans || [],
		admin_functionalities: JSON.parse(JSON.stringify(this.props.admin_functionalities || []))
	}

	componentDidMount() {
		if (this.props.user) {
			const next_payment = this.props.user?.plan_user?.last_payment?.next_payment;

			this.setState({
				form: {
					...this.state.form,
					name: this.props.user?.name || '',
					email: this.props.user?.email || '',
					document: this.props.user?.document || '',
					phone: this.props.user?.phone || '',
					date: next_payment ? moment(next_payment).toDate() : '',
					plan_id: this.props.user?.plan_user?.plan_id || ''
				},
				admin_functionalities: this.state.admin_functionalities?.map((item: any) => {
					item.active = this.props.user?.admin_functionalities?.find((i: any) => i.id) != null;
					return item;
				})
			});
		}
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	submit = async () => {
		if (this.props.user) {
			await UserService.update({
				...this.state.form,
				user_id: this.props.user?.id,
				date: this.state.form.date ? moment(this.state.form.date).format('YYYY-MM-DD') : '',
				admin_functionalities: this.state.admin_functionalities?.filter((i: any) => i.active).map((i: any) => i.id)
			});
		}
		else {
			await UserService.create({
				...this.state.form,
				date: this.state.form.date ? moment(this.state.form.date).format('YYYY-MM-DD') : '',
				admin_functionalities: this.state.admin_functionalities?.filter((i: any) => i.active).map((i: any) => i.id)
			});
		}
		Socket.emit(SocketEvents.UPDATE_SUPERADMIN,{
			admin_id: this.props.user?.id
		});
		this.props.onClose();
	}

	render() {
		const { admin_functionalities } = this.state;

		return (
			<div id="modal-create-user">
				<div className="content-create row">
					<div className="col-md-12">
						<Input 
							value={ this.state.form.name }
							name="name" 
							label="Nombre"
							onChange={ this.change } />
					</div>
					<div className="col-md-12">
						<Input 
							value={ this.state.form.email }
							name="email" 
							label="Correo electrónico"
							onChange={ this.change } />
					</div>
					<div className="col-md-6">
						<Input 
							value={ this.state.form.document }
							name="document" 
							label="Documento de Identidad"
							onChange={ this.change } />
					</div>
					<div className="col-md-6">
						<Input 
							value={ this.state.form.phone }
							name="phone" 
							label="Teléfono"
							onChange={ this.change } />
					</div>
					<div className="col-md-6">
						<DatePicker
							label="Fecha de corte"
							onChange={ (text: string) => {
								this.change({
									target: {
										value: text,
										name: 'date'
									}
								});
							} }
							value={ this.state.form.date }
						/>
					</div>
					<div className="col-md-6">
						<Select
							name="plan_id"
							label="Plan"
							onChange={ (e: any) => this.change(e) }
							value={ this.state.form.plan_id }
							options={ this.state.plans.map((item: any) => {
								return {
									value: item.id,
									label: item.name + (item.price == 0 ? '' : ' (' + (item.quantity_users ? (item.quantity_users + ' usuario(s)') : 'Ilimitado') + ')')
								}
							}) } />
					</div>
					<div className="col-md-6">
						<Input 
							type="password"
							value={ this.state.form.password }
							name="password" 
							label={ this.props.user != null ? 'Cambiar contraseña (Opcional)' : 'Contraseña' }
							onChange={ this.change } />
					</div>
					{
						this.props.user == null && (
							<div className="col-md-6">
								<Input 
									type="password"
									value={ this.state.form.password_confirmation }
									name="password_confirmation" 
									label="Confirmar contraseña"
									onChange={ this.change } />
							</div>
						)
					}
					<div className="col-md-12">
						<p>Funcionalidades</p>
						{
							admin_functionalities.map((item: any, index: number) => {
								return (
									<div className="check-container" onClick={ () => {
										let admin_functionalities = [...this.state.admin_functionalities];
										admin_functionalities[index].active = !admin_functionalities[index].active;
										this.setState({
											admin_functionalities
										});
									} }>
										<div className={ `check ${ item.active ? 'active' : '' }` }></div>
										<p>{ item.name }</p>
									</div>
								)
							})
						}
					</div>
				</div>
				<div className="row row-buttons">
					<div className="col-6 text-center">
						<Button type="button" className="btn-close" onClick={ () => this.props.onClose() }>
							Cerrar
						</Button>
					</div>
					<div className="col-6 text-center">
						<Button type="button" className="btn-save btn-green" onClick={ () => this.submit() }>
							Guardar
						</Button>
					</div>
				</div>
			</div>
		)
	}
}

export default ModalCreateUser;